import React, { useState } from 'react';
import {
  Container,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  LinearProgress
} from '@mui/material';
import { DashboardLayout } from '../../components/DashboardLayout';
import CustomToolbar from '../../components/general/CustomToolbar';
import LeadChart from '../../components/leads/LeadChart';
import LeadTable from '../../components/leads/LeadTable';
import { tProduct } from '../../types/types';
import { useStore } from '../../store';
import { useQuery } from '@tanstack/react-query';
import { productLeads, userProducts } from '../../api';
import { queryKeys } from '../../lib/queryKeys';

const LeadsDashboard: React.FC = () => {
  const { user }: any = useStore((state: any) => state);
  const { data: products, isLoading } = useQuery(
    [queryKeys.userProducts, { userId: user?.id }],
    userProducts,
    {
      enabled: !!user
    }
  );
  const [selectedProduct, setSelectedProduct] = useState<tProduct | null>(products?.[0] || null);

  const handleProductChange = (event: SelectChangeEvent<any>) => {
    const product = products.find((p: tProduct) => p.id === event.target.value);
    setSelectedProduct(product || {});
  };

  // to-do handle file upload and download

  const { data: leads, isLoading: leadsLoading } = useQuery(
    [queryKeys.productLeads, { productId: selectedProduct?.id }],
    productLeads,
    {
      enabled: !!selectedProduct
    }
  );
  console.log(selectedProduct, ' selelele');

  return (
    <DashboardLayout>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 4
          }}>
          <Container maxWidth={false}>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel id="product-select-label">Select Product</InputLabel>
              <Select
                labelId="product-select-label"
                value={selectedProduct?.id || ''}
                onChange={handleProductChange}
                label="Select Product">
                {products?.map((product: tProduct) => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedProduct && (
              <CustomToolbar
                title={`Leads ${selectedProduct?.name} (${leads?.length || 0})`}
                id={selectedProduct?.id}
                addPath={`/leads/add/${selectedProduct.id}`}
              />
            )}

            <Grid container spacing={3}>
              <Grid item xs={8}>
                <LeadTable leads={leadsLoading ? [] : leads} />
              </Grid>
              <Grid item xs={4}>
                {leads && <LeadChart leads={leads} />}
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </DashboardLayout>
  );
};

export default LeadsDashboard;
