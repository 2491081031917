import { FilterListOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  MenuItem,
  MenuList,
  Popover,
  SvgIcon,
  TextField,
  Typography
} from '@mui/material';
import { useRef, useState } from 'react';
import '../../assets/styles/resources.css';
import { Search as SearchIcon } from '../../icons/search';
import { useNavigate } from 'react-router-dom';

const CustomToolbar = (props: any) => {
  const { title, id, addPath } = props;
  const navigate = useNavigate();
  const settingsRef = useRef(null);
  const [openImportPopover, setOpenImportPopover] = useState(false);

  return (
    <Box {...props}>
      <Box className="resources__header">
        <Typography className="m" variant="h4">
          {title?.toUpperCase()}
        </Typography>
        <Button
          ref={settingsRef}
          onClick={() => setOpenImportPopover(true)}
          startIcon={<FilterListOutlined fontSize="small" />}
          sx={{
            mx: {
              xs: 1,
              sm: 0,
              md: 15
            },
            border: '1px solid #000'
          }}>
          Sort by / Filter
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex' }}>
              <TextField
                fullWidth
                sx={{ flex: 1, marginRight: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon color="action" fontSize="small">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder={`Search ${title}`}
                variant="outlined"
              />

              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate(addPath, { state: { id } });
                }}>
                Add new {title}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>

      <Popover
        anchorEl={settingsRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        onClose={() => setOpenImportPopover(false)}
        open={openImportPopover}
        PaperProps={{
          sx: { width: '300px' }
        }}>
        <MenuList>
          <MenuItem>ID</MenuItem>
          <MenuItem>Ascending</MenuItem>
          <MenuItem>Descending</MenuItem>
        </MenuList>
      </Popover>
    </Box>
  );
};
export default CustomToolbar;
