import axios from 'axios';
import { Buffer } from 'buffer';
import { OpenAI } from 'langchain';
import { tAddApp, tAddBudget, tAddTimeSheet, tLead, tTimeEntry } from '../types/types';
let baseURL = 'https://everlasting.work/api/v1';

if (process.env.NODE_ENV === 'development') {
  baseURL = 'http://localhost:5071/api/v1';
}
const api = axios.create({
  baseURL: baseURL
});

export const setDefaultHeaders = (token?: string) => {
  if (!token) {
    const userString = localStorage.getItem('user') ?? '';
    const user = userString && JSON.parse(userString);
    token = process.env.REACT_APP_authToken || user?.token;
  }

  if (token) {
    api.defaults.headers.common['Authorization'] = `Basic ${token}`;
  }
};

export const registerUser = async (data: any) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.post('/user/signup', data);
  return response.data;
};
export const registerCustomerUser = async (data: any) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.post('/reach/customer/add', data);
  return response.data;
};
export const updateUser = async (data: any) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.put('/user/update', data);
  return response.data;
};

export const loginUser = async ({ email, password }: { email: string; password: string }) => {
  const token = Buffer.from(`${email}:${password}`).toString('base64');
  setDefaultHeaders(token);
  const response = await api.post('/user/login', { email, password });
  return response.data;
};

export const userExist = async (email: string | undefined) => {
  if (email) {
    setDefaultHeaders(process.env.REACT_APP_authToken);
    const response = await api.get(`/user/exists/${email}`);
    return response.data;
  }
};

export const sendToken = async (email: string | undefined) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.get(`/user/dotoken/${email}`);
  return response.data;
};

export const resetPassword = async (data: {
  email: string;
  token: number | string;
  password: number | string;
}) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.post('/user/reset/password', data);
  return response.data;
};

export const addCustomer = async (data: any) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.post('/reach/customer/add', data);
  return response.data;
};

export const getCustomers = async () => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.get('/reach/customer/all');
  return response.data;
};
export const getCustomer = async (id: string | undefined) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.get(`/reach/customer/${id}`);
  return response.data;
};

export const getMyCustomers = async (id: number | string) => {
  setDefaultHeaders();
  if (!id) return null;
  const response = await api.get(`/reach/customer/my?owner=${id}`);
  return response.data;
};

export const updateCustomer = async (data: any) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.put('/reach/customer/edit', data);
  return response.data;
};

export const addReceipt = async (data: any) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.post('/reach/receipt/add', data);
  return response.data;
};

export const getReceipts = async () => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.get('/reach/receipt/all');
  return response.data;
};

export const getCustomerReceipts = async ({ queryKey }: any) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const [, { customerId }] = queryKey;
  if (!customerId) return;
  const response = await api.get(`/reach/receipt/customer/${customerId}`);
  return response.data;
};

export const getAllInvoices = async () => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.get('/reach/invoice/invoices');
  return response.data;
};
export const addProduct = async (data: any) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.post('/reach/product/add', data);
  return response.data;
};

export const updateProduct = async (data: any) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.put(`reach/product/${data.id}`, data);
  return response.data;
};

export const getProductDetail = async (id: string | undefined) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.get(`/reach/product/${id}`);
  return response.data;
};

export const getAllProducts = async () => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.get('/reach/product/all');
  return response.data;
};

export const getCustomerProducts = async ({ queryKey }: any) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const [, { customerId }] = queryKey;
  if (!customerId) return;
  const customer = customerId ? `?customerId=${customerId}` : '';
  const response = await api.get(`/reach/product/customer/products${customer}`);
  return response.data;
};

export const expiringProducts = async (expiry?: string) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const date = expiry ? `?expiry=${expiry}` : '';
  const response = await api.get(`/reach/product/expiring${date}`);
  return response.data;
};

export const addNewStaff = async (data: { ownerId: number | string; id: number | string }) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.post('/reach/customer/user', data);
  return response.data;
};

export const getMyStaff = async ({ queryKey }: any) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const [, { customerId }] = queryKey;
  const customer = customerId ? `?customerId=${customerId}` : '';
  if (!customer) return;
  const response = await api.get(`/reach/customer/users${customer}`);
  return response.data;
};

export const getProduct = async ({ queryKey }: any) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const [, { customerId }] = queryKey;
  const customer = customerId ? `?customerId=${customerId}` : '';
  const response = await api.get(`/reach/product/customer/products${customer}`);
  return response.data;
};
export const userProducts = async ({ queryKey }: any) => {
  const [, { userId }] = queryKey;
  if (!userId) return;
  const response = await api.get(`/reach/product/user/products?userId=${userId}`);
  return response.data;
};

export const getStaffGroup = async ({ queryKey }: any) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const [, { adminId }] = queryKey;
  const admin = adminId ? `?adminId=${adminId}` : '';
  const response = await api.get(`/reach/customer/staff/group${admin}`);
  return response.data;
};

export const getLastInvoice = async ({ queryKey }: any) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const [, { customerId }] = queryKey;
  if (!customerId) return;
  const customer = customerId ? `?customerId=${customerId}` : '';
  const response = await api.get(`/reach/invoice/number${customer}`);
  return response.data;
};
const getUserID = ({ queryKey }: any) => {
  const [, { userId }] = queryKey;
  if (!userId) return;
  return userId ? `?userId=${userId}` : '';
};

export const getUserInvoices = async ({ queryKey }: any) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const user = getUserID(queryKey);
  const response = await api.get(`/reach/invoice/user${user}`);
  return response.data;
};

export const createInvoice = async (data: any) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.post('/reach/invoice/invoice', data);
  return response.data;
};

export const getInvoice = async ({ queryKey }: any) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const [, { id }] = queryKey;
  if (!id) return;
  const invoiceId = id ? `?iid=${id}` : '';
  const response = await api.get(`/reach/invoice/one${invoiceId}`);
  return response.data;
};

export const pendingMsg = async (data: { userId: string; pending: boolean }) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.get(`/reach/message/lead?usr=${data.userId}&pending=${data.pending}`);
  return response.data;
};

export const editMsg = async (data: {
  message: any;
  id: string | number;
  usr: string | number;
  pending: boolean;
}) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.put('/reach/message/edit', data);
  return response.data;
};

export const savePrompt = async (data: any) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.post('/reach/message/add', data);
  return response.data;
};

export const addUsersToCustomer = async (data: { emails: string[]; customerId: string }) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.post('/reach/customers/invite', data);
  return response.data;
};

export const removeUsersFromCustomer = async (data: { emails: string[]; customerId: string }) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.delete('/reach/customers/users', { data });
  return response.data;
};

export const getAllUsersFromCustomer = async ({ queryKey }: any) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const [, { customerId }] = queryKey;
  if (!customerId) return;
  const customer = customerId ? `?customerId=${customerId}` : '';
  const response = await api.get(`/reach/customer/users${customer}`);
  return response.data;
};

export const dochat = async (prompt: string) => {
  const llm = new OpenAI({
    temperature: 0.9,
    openAIApiKey: process.env.REACT_APP_AIKEY
  });
  const result = await llm.predict(prompt);
  return result;
};
export const domainSearch = async (domain: string) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const response = await api.get(`/reach/builder/domain?key=${domain}`);
  return response.data;
};
export const getStripe = async (obj: any) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const resp = await api.post('/pay/session', obj);
  return resp.data;
};

export const generatePrompt = async (obj: { prompt: string; useremail: string }) => {
  const resp = await axios.post('http://horacelearning.us/seo/prompt', obj, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_authToken}`
    }
  });
  return resp.data;
};
export const getApps = async ({ queryKey }: any) => {
  const user = queryKey[1]?.userId;
  setDefaultHeaders(user?.token);
  const resp = await api.get(`/esapp/user?usr=${user.id}`);
  return resp.data;
};
export const addApp = async (data: tAddApp) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const resp = await api.post('/esapp/add', data);
  return resp.data;
};
export const getTimeSheet = async () => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const resp = await api.get('/esapp/time/all');
  return resp.data;
};
export const addTimeSheet = async (data: tAddTimeSheet) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const resp = await api.post('/esapp/time/add', data);
  return resp.data;
};
export const getBudgets = async () => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const resp = await api.get('/esapp/budget/all');
  return resp.data;
};
export const addBudget = async (data: tAddBudget) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const resp = await api.post('/esapp/budget/add', data);
  return resp.data;
};

export const addTimeEntry = async (data: tTimeEntry) => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const resp = await api.post('/esapp/time/entry', data);
  return resp.data;
};

export const allEntries = async () => {
  // setDefaultHeaders(process.env.REACT_APP_authToken);
  const resp = await api.get('/esapp/time/entries');
  return resp.data;
};
export const getWebEdit = async ({ queryKey }: any) => {
  const pid = queryKey[1].pid;
  const resp = await api.get(`/sec/product?pid=${pid}`);
  return resp.data;
};
export const editWebsite = async (data: tTimeEntry) => {
  const resp = await api.post('sec/builder', data);
  return resp.data;
};
export const newLead = async (data: tLead) => {
  const resp = await api.post(`esapp/lead/`, data);
  return resp.data;
};
export const productLeads = async ({ queryKey }: any) => {
  const [, { productId }] = queryKey;
  if (!productId) return;
  const resp = await api.get(`/esapp/lead/product/${productId}`);
  return resp.data;
};
export const editLead = async (data: tLead) => {
  const resp = await api.put(`esapp/lead/`, data);
  return resp.data;
};
export const addRemark = async (id: number, remark: string) => {
  const resp = await api.put(`esapp/lead/remark?id=${id}&remark=${remark}`);
  return resp.data;
};
